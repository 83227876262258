// Importing JavaScript
//
// You have two choices for including Bootstrap's JS files—the whole thing,
// or just the bits that you need.


// Option 1
//
// Import Bootstrap's bundle (all of Bootstrap's JS + Popper.js dependency)

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AOS from "aos/dist/aos.js";
import "bootstrap/js/dist/popover";

AOS.init();

const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
const appendAlert = (message, type) => {
  const wrapper = document.createElement('div.row')
  wrapper.innerHTML = [
    `<div class="alert alert-${type} col-sm-7 col-md-9 col-xl-6 alert-dismissible" role="alert" id="alert">`,
    `   <div>${message}</div>`,
    '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
    '</div>'
  ].join('')

  alertPlaceholder.append(wrapper)
}

const fieldset = document.querySelector('fieldset');
const searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('sendForm')) {
  document.body.classList.add("overflow-hidden");
  const controls = document.querySelectorAll('.control');
  fieldset.setAttribute("disabled", "disabled");
  controls.forEach((control) => {
    control.style.opacity = 0.2;
  });
  if (searchParams.get("sendForm") == "success") {
    appendAlert("The form has been sent successfully", "success");
  }
  else {
    appendAlert("Failed", "warning")
  }
  const alert = document.getElementById('alert');
  alert.addEventListener('closed.bs.alert', event => {
    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    window.history.pushState({ path: newurl }, '', newurl);
    document.body.classList.remove("overflow-hidden");
    fieldset.removeAttribute("disabled");
    controls.forEach((control) => {
      control.style.opacity = 1;
    });
  })
};


function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 && rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

var time = 1,
  cc = 1;
if (document.querySelector('file-upload')) {
  document.getElementById('file-upload').addEventListener('change', function () {
    var fileSize = this.files[0].size / 1024 / 1024; // in MB
    if (fileSize > 10) {
      alert('File size exceeds 10 MB');
      this.value = ''; // clear the control
    }
  });
  document.querySelector("#file-upload").onchange = function () {
    document.querySelector(".custom-file-upload").textContent = this.files[0].name;
  };
};

window.onscroll = function () {
  if (document.querySelector('#counter')) {
    var counter = document.getElementById('counter');
    if (counter && isElementInViewport(counter)) {
      if (cc < 2) {
        var numbers = Array.from(document.getElementsByClassName('number'));
        numbers.forEach(function (number) {
          number.classList.add('viz');
        });
        var divs = Array.from(document.getElementsByClassName('number'));
        divs.forEach(function (div) {
          var num = div.getAttribute('data-num');
          var step = 1000 * time / num;
          var that = div;
          var j = 1;
          var int = setInterval(function () {
            if (j <= num) {
              that.innerHTML = j;
            } else {
              cc = cc + 2;
              clearInterval(int);
            }
            j++;
          }, step);
        });
      };
    };
  };
};

const main = document.querySelector('main');



if (window.location.pathname === '/about-us.html') {
  if (!main.classList.contains('uniqueness')) {
    main.classList.add('uniqueness');
  }
}
else {
  if (!main.classList.contains('uniqueness')) {
    main.classList.remove('uniqueness');
  }
}

function insertCurrentYear(elementId) {
  // Получаем текущий год
  const currentYear = new Date().getFullYear();

  // Находим элемент по ID
  const element = document.getElementById(elementId);

  // Устанавливаем текст элемента равным текущему году
  if (element) {
      element.textContent = currentYear;
  } else {
      console.error("Элемент с ID '" + elementId + "' не найден.");
  }
}
document.addEventListener("DOMContentLoaded", function() {
  insertCurrentYear("year");
});
